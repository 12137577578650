<template>
 <div>
    <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-menu-button></ion-menu-button>
            </ion-buttons>
            <ion-title>
             <ion-icon class="title spacer" :icon="happyOutline"/>
             <ion-label class="title">
              {{currentUser}}さん    
             </ion-label>
            </ion-title>
            <ion-buttons slot="end">
              <ion-button @click="openSecond()">
               <ion-icon :icon="personCircleOutline"/>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
    <ion-menu side="start" menu-id="first" content-id="main">
      <ion-header>
        <ion-toolbar>
          <ion-title class="syaberu-menu">SYABERU</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-item href="/home">
            <ion-icon :icon="mailOutline"/>
           <ion-label class="syaberu-menu">新着</ion-label>
          </ion-item>
          <ion-item href="/notificationSearch">
            <ion-icon :icon="mailOpenOutline"/>
           <ion-label class="syaberu-menu">これまでのお知らせ</ion-label>
          </ion-item>
          <ion-item href="/payment">
            <ion-icon :icon="card"/>          
            <ion-label class="syaberu-menu">精算</ion-label>
          </ion-item>
          <ion-item href="/hello">
            <ion-icon :icon="megaphoneOutline" />
            <ion-label class="syaberu-menu">サプライズ</ion-label>
          </ion-item>
          <ion-item href="/stampRally">
            <ion-icon :icon="gameController" />
            <ion-label class="syaberu-menu">ミッション</ion-label>
          </ion-item>
          <ion-item href="/missionBoard">
            <ion-icon :icon="earthOutline" />
            <ion-label class="syaberu-menu">ミッション達成状況</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
   </ion-menu>
   <!-- 右側 -->
   <ion-menu side="end" menu-id="second" content-id="main">
      <ion-header>
        <ion-toolbar>
          <ion-title class="syaberu-menu">SYABERU</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-item>
            <ion-icon :icon="hammerOutline" />
            <ion-label class="syaberu-menu">個人情報編集</ion-label>
          </ion-item>
          <ion-item href="/selectUser" v-if="switchUserVisible">
            <ion-icon :icon="swapHorizontal" />
            <ion-label class="syaberu-menu">表示する児童の変更</ion-label>
          </ion-item>
          <ion-item href="/resetMission">
            <ion-icon :icon="refreshOutline" />
            <ion-label class="syaberu-menu">ミッションリセット</ion-label>
          </ion-item>
          <ion-item href="/logout">
            <ion-icon :icon="logOutOutline" />
            <ion-label class="syaberu-menu">ログアウト</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
   </ion-menu>

   <ion-router-outlet id="main"></ion-router-outlet>
  </div>
</template>

<script>
import { IonIcon,
IonHeader,
IonToolbar,
IonTitle,
menuController,
IonList,
IonItem,
IonContent,
IonMenu,
IonButtons,
IonButton,
IonMenuButton,
IonLabel,
IonRouterOutlet
} from '@ionic/vue';
import { menu,
personCircleOutline, 
sparkles, 
card, 
notifications, 
megaphoneOutline, 
gameController,
swapHorizontal,
search,
mailOutline,
mailOpenOutline,
happyOutline,
logOutOutline,
hammerOutline,
refreshOutline,
earthOutline
} from 'ionicons/icons';
export default  {
  name: 'SyaberuMenu',
  components: {
    IonHeader, 
    IonToolbar,
    IonTitle,
    IonIcon,
    IonList,
    IonItem,
    IonContent,
    IonMenu,
    IonButtons,
    IonButton,
    IonMenuButton,
    IonLabel,
    IonRouterOutlet
 },
  data(){
    return {
      title : this.$store.state.title,
      switchUserVisible : false,
      currentUser : null,
    }
  },
  setup() {
    return {
      notifications,
      personCircleOutline,
      sparkles,
      card,
      megaphoneOutline,
      gameController,
      menu,
      swapHorizontal,
      search,
      mailOutline,
      mailOpenOutline,
      happyOutline,
      logOutOutline,
      hammerOutline,
      refreshOutline,
      earthOutline
    }
  },
  async mounted(){
     const user = this.$store.state.activeUser;
     if(user){
       this.currentUser = user.userName;
       if(this.$store.state.users){
         this.switchUserVisible = true;
       }
     }else{
       alert('unknown error...');
       this.$router.push("/login");
     }
  },
  methods : {
    async openSecond(){
      await menuController.enable(true, 'second');
      await menuController.open('second');
    }
  }
}
</script>
<style scoped>
 .title{
   vertical-align: middle;
   font-size: 12pt;
 }
 .spacer{
   padding-right:0.3em;
 }
.syaberu-menu{
   padding-left: 0.5em;
   font-size: 0.9em;
   font-family: "Hiragino Maru Gothic ProN W4",sans-serif
 }
</style>
