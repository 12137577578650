<template>
  <ion-page>
    <syaberu-menu/>
    <ion-content fullscreen>
      <ion-label><h1 class="ion-padding">内訳:</h1></ion-label>
      <ion-card v-bind:key="invoice" v-for="(invoice) of getInvoices()">
         <ion-item>
            <ion-label>
              <h4>{{invoice.year}}年{{invoice.month}}月</h4>
              <h2><strong>{{getCommaSeparatedPrice(invoice.sum)}}円</strong></h2>
            </ion-label>
            <ion-label>
              <ion-grid>
                <ion-row class="rightAligned">
                  <ion-col><small>給食費</small></ion-col>
                  <ion-col><h4>{{getCommaSeparatedPrice(invoice.meal)}}円</h4></ion-col>
                </ion-row>
                <ion-row class="rightAligned">
                  <ion-col><small>延長保育料</small></ion-col>
                  <ion-col><h4>{{getCommaSeparatedPrice(invoice.extend)}}円</h4></ion-col>
                </ion-row>
                <ion-row class="rightAligned">
                  <ion-col><small>購入備品</small></ion-col>
                  <ion-col><h4>{{getCommaSeparatedPrice(invoice.purchasedProduct)}}円</h4></ion-col>
                </ion-row>
              </ion-grid>
            </ion-label>
          </ion-item>
      </ion-card>
       <ion-toolbar>
        <ion-label><h1 class="ion-padding">合計精算金額: {{getDisplayedSum()}} 円</h1></ion-label>
      </ion-toolbar>
      <ion-label>
      <div id="card-container"></div>
      <ion-grid>
        <ion-row class="ion-justify-content-between">
          <ion-button @click="handlePayment" size="large" shape="round" color="warning" id="card-button">精算</ion-button>
          <ion-button @click="backToPayment" shape="round" size="large" color="light">戻る</ion-button>
        </ion-row>
      </ion-grid>
       <div id="payment-status-container"></div>
      </ion-label>
  </ion-content>
  </ion-page>
</template>
<script>

const FUNCTIONS_HOST_NAME = 'https://us-central1-attendancerecorder-admin.cloudfunctions.net';
const API_INFORMATION = FUNCTIONS_HOST_NAME + "/apiInformation";
let card;
import { IonPage,
IonContent, 
IonButton,
IonToolbar,
IonLabel,
IonCard,
IonGrid,
IonRow,
IonCol,
IonItem,
} from '@ionic/vue';
import * as Square from '@square/web-sdk';
import  SyaberuMenu from './SyaberuMenu.vue';
export default  {
  name: 'CreditCardInput',
  props: {
    amount: { type: String, default: '0' },
    selectedIndex : {type: Array, default:()=>[]},
    invoices: {type: Array, default:()=>[]}
  },
  components: { 
  IonPage,
  IonContent,
  IonButton,
  IonToolbar,
  IonLabel,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  SyaberuMenu },
  data(){
    return {
      userId : this.$store.state.activeUser.userId,
      tenantId : this.$store.state.activeUser.tenantId
    }
  },
  async mounted(){
    this.$root.startLoading();
    const apiInfo = await this.loadApiInfo();
    await this.setUpSquare(apiInfo.appKey,apiInfo.locationId);
    this.$root.endLoading();
  },
  methods : {
    async handlePayment(event){
        if(event){
          event.preventDefault();
          event.stopPropagation();
        }
        try {
          const result = await card.tokenize();
          if (result.status === 'OK') {
            this.disabled = false;
            const payment = {
                "token" : result.token,
                "amount" : this.amount,
                "invoices" : this.getInvoices()
            }
            this.$store.commit('setPayment',payment);
            //次の画面に遷移する。ここはiframeの中だからAxiosが真面目に動けない
            this.$router.push("/paymentProcedure");
          }else{
            alert('カード情報を正しく入力してから精算ボタンを教えてください');
          }
        } catch (e) {
          console.error(e);
        }
    },
    backToPayment(){
      this.$router.push("/payment");
    },
    async setUpSquare(appId,locationId){
      // const that = this;
      const payments = await Square.payments(appId,locationId);
      card = await payments.card();
      await card.attach('#card-container');
    },
    async loadApiInfo(){
      let params = { "params" : {
        'tenantId' : this.tenantId,
      }};
      let response = await this.axios.get(API_INFORMATION,params);
      return response.data;
    },
    /**
     * @function
     * @return{string}
     * @param{number} num  3000
     * @param{string} "3,000"
     */
    getCommaSeparatedPrice(num){
      return num ? num.toLocaleString() : "0";
    },
    /**
     * @function
     * @return{string}
     * @param{number} num  3000
     * @param{string} "3,000"
     */
    getDisplayedSum(){
      return this.amount.toLocaleString();
    },
    /**
     * 絶対良い方法あると思うんだけど、Serialize / Deserializeしないと動かせなかった...
     */
    getInvoices(){
      console.log(this.selectedIndex);
      let invoices = [];
      this.invoices.forEach(invoice => {
        invoices.push(JSON.parse(invoice));
      });
      let result = [];
      for (const idx in JSON.parse(this.selectedIndex)) {
       result.push(invoices[idx]);        
      } 
      return result;
    }
}}
</script>